import { useMutation } from "@apollo/client";
import { useToast } from "../Toast/toastHook";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { DELETE_AGENT } from "../../graphql/directoryQueries";

const CompanyDirectoryDeleteView = ({
  agentId,
  handleClose,
  setShowDeleteModal,
  refetchData,
  companyId,
}) => {
  const toast = useToast(3000);
  const [deleteAgent, { loading: deleteLoading }] = useMutation(DELETE_AGENT);
  const handleCancelClick = () => {
    setShowDeleteModal(false);
  };

  const handleDeleteDirectory = async (agentId) => {
    const payload = {
      variables: {
        agentId,
        companyId,
      },
    };

    try {
      const { data } = await deleteAgent(payload);

      if (data) {
        toast("success", "Agent deleted successfully");
        handleClose();
        refetchData();
      } else {
        toast("error", "Agent not deleted or does not exist");
        handleClose();
      }
    } catch (error) {
      toast("error", "Failed to delete agent");
      handleClose();
    }
  };

  return (
    <form>
      <p>Are you sure you want to delete this Contact?</p>
      <div className="mt-6 flex space-x-2">
        <button
          type="button"
          disabled={deleteLoading}
          onClick={() => handleDeleteDirectory(agentId)}
          className="flex w-1/2 justify-center rounded bg-secondary p-3 font-medium text-white outline hover:opacity-90 disabled:bg-slate-400"
        >
          {deleteLoading ? (
            <div className="animate-spin">
              <AiOutlineLoading3Quarters size={22} />
            </div>
          ) : (
            "Yes"
          )}
        </button>
        <button
          onClick={handleCancelClick}
          disabled={deleteLoading}
          className="flex w-1/2 justify-center rounded bg-primary p-3 font-medium text-white hover:opacity-90 disabled:bg-slate-400"
        >
          No
        </button>
      </div>
    </form>
  );
};

export default CompanyDirectoryDeleteView;
