import { useMutation } from "@apollo/client";
import { useRef } from "react";
import { Formik, FieldArray } from "formik";
import Input from "../Input";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { EDIT_AGENT } from "../../graphql/directoryQueries";
import { useToast } from "../Toast/toastHook";

const CompanyDirectoryEditView = ({
  companyId,
  agentId,
  handleClose,
  refetchData,
  initialValues,
}) => {
  const [editAgent, { loading: editLoading }] = useMutation(EDIT_AGENT, {
    onCompleted(result) {
      toast("success", `Successfully saved agent!`);
      formikRef.current?.resetForm();
      handleClose();
    },
    onError(e) {
      toast("error", e.message);
    },
  });

  const toast = useToast(3000);
  const formikRef = useRef();

  const transformedInitialValues = {
    details: [initialValues],
  };

  const handleSave = (values, { resetForm }) => {
    const { details } = values;
    if (details.length === 0) {
      toast("error", "Please add at least one agent");
      return;
    }

    for (const detail of details) {
      for (const [key, value] of Object.entries(detail)) {
        if (!value) {
          toast("error", `Please fill in the ${key} field for all agents`);
          return;
        }
      }
    }

    const { _id, __typename, ...rest } = details[0];
    const payload = {
      variables: {
        agentInfo: rest,
        companyId: companyId,
        agentId: agentId,
      },
    };

    editAgent(payload).then(() => {
      resetForm();
      refetchData();
    });
  };
  return (
    <Formik
      innerRef={formikRef}
      initialValues={transformedInitialValues}
      enableReinitialize={true}
      onSubmit={async (values, { resetForm }) => {
        handleSave(values, { resetForm });
      }}
    >
      {({ values, errors, isSubmitting, handleChange, handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <div className="mb-3 flex flex-col gap-6">
            <FieldArray name="details">
              {({ push }) => (
                <>
                  {values?.details?.map((detail, index) => (
                    <div key={index} className="mt[-30px]">
                      <div className="w-full mb-3 ">
                        <Input
                          label={"Name"}
                          name={`details[${index}].name`}
                          error={errors.details?.[index]?.name}
                          value={detail.name}
                          onChange={handleChange}
                          type="text"
                          placeholder={"Name"}
                        />
                      </div>
                      <div className="w-full mb-3 ">
                        <Input
                          label={"Title"}
                          name={`details[${index}].position`}
                          error={errors.details?.[index]?.position}
                          value={detail.position}
                          onChange={handleChange}
                          type="text"
                          placeholder={"Title"}
                        />
                      </div>
                      <div className="w-full mb-3 ">
                        <Input
                          label={"Office Address"}
                          name={`details[${index}].address`}
                          error={errors.details?.[index]?.address}
                          value={detail.address}
                          onChange={handleChange}
                          type="text"
                          placeholder={"Office Address"}
                        />
                      </div>
                      <div className="w-full mb-3 ">
                        <Input
                          label={"Email Address"}
                          name={`details[${index}].email`}
                          error={errors.details?.[index]?.email}
                          value={detail.email}
                          onChange={handleChange}
                          type="email"
                          placeholder={"Email Address"}
                        />
                      </div>
                      <div className="w-full mb-3 ">
                        <Input
                          label={"Primary Contact Number"}
                          name={`details[${index}].phone`}
                          error={errors.details?.[index]?.phone}
                          value={detail.phone}
                          onChange={handleChange}
                          type="text"
                          placeholder={"Primary Contact Number"}
                        />
                      </div>
                      <div className="w-full mb-3 ">
                        <Input
                          label={"Website"}
                          name={`details[${index}].url`}
                          error={errors.details?.[index]?.url}
                          value={detail.url}
                          onChange={handleChange}
                          type="text"
                          placeholder={"Website"}
                        />
                      </div>
                    </div>
                  ))}
                </>
              )}
            </FieldArray>
          </div>

          <div className="mt-6 flex space-x-2">
            <button
              type="button"
              disabled={editLoading}
              onClick={() => handleClose()}
              className="flex w-1/2 justify-center rounded bg-secondary p-3 font-medium text-white outline hover:opacity-90 disabled:bg-slate-400"
            >
              Close
            </button>
            <button
              type="submit"
              disabled={isSubmitting || editLoading}
              className="flex w-1/2 justify-center rounded bg-primary p-3 font-medium text-white hover:opacity-90 disabled:bg-slate-400"
            >
              {editLoading ? (
                <div className="animate-spin">
                  <AiOutlineLoading3Quarters size={22} />
                </div>
              ) : (
                "Save"
              )}
            </button>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default CompanyDirectoryEditView;
