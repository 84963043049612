import * as yup from "yup";
export const validationSchema = yup.object().shape({
  companyName: yup.string().required("Company Name is Required").max(50),
  userLimit: yup.number().required("User limit is Required"),
  onBoardingPassword: yup.string().required("Onboarding password is required"),
  officeName: yup.string().required("Office name is required"),
  officeAddress: yup.string().required("Address is required"),
  officeCity: yup.string().required("City is required"),
  officeState: yup.string().required("State is required"),
  officeZip: yup.string().required("Zip/Postal Code is required"),
  officePhone: yup.number().required(),
    // .matches(/^\([0-9]{3}\)[0-9]{3}-[0-9]{4}$/, "Must contain 10 digits")
    // .nullable(),
  
    userFirstName: yup.string().required("First name is required"),
    userLastName: yup.string().required("Last name is required"),
    userEmail: yup.string().required("Email is required"),
    userPhone: yup.number().required("Phone is required"),
});

export const editCompanyValidationSchema = yup.object().shape({
  companyName: yup.string().required("Company Name is Required"),
  userLimit: yup.number().required("User limit is Required"),
  onBoardingPassword: yup.string().required("Onboarding password is required"),
});
