import { useMutation } from "@apollo/client";
import {
  UPDATE_DIRECTORY,
  ADD_DIRECTORY,
  GET_DIRECTORIES,
} from "../../graphql/directoryQueries";
import { useToast } from "../Toast/toastHook";
import { useEffect, useRef, useState } from "react";
import { Formik } from "formik";
import Input from "../Input";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { uploadImages } from "../../services/upload";
import { HiOutlineCloudUpload } from "react-icons/hi";

const DirectoryForm = ({
  directory,
  formType,
  handleClose,
  onDirectoryAdded,
  clickedFrom,
}) => {
  const user = JSON.parse(localStorage.getItem("userInfo"));
  const [initialValues, setInitialValues] = useState({
    title: "",
    icon: "",
  });

  const toast = useToast(3000);
  const formikRef = useRef();

  // Mutation for adding a directory
  const [
    addDirectory,
    { data: addData, loading: addLoading, error: addError },
  ] = useMutation(ADD_DIRECTORY, {
    onCompleted(result) {
      toast("success", `Successfully saved ${result.addDirectory?.title}!`);
      formikRef.current?.resetForm();
      handleClose();
      onDirectoryAdded();
    },
    onError(e) {
      toast("error", `Directory title already exists`);
      console.error(e);
    },
  });

  // Mutation for updating a directory
  const [
    updateDirectory,
    { data: updateData, loading: editLoading, error: updateError },
  ] = useMutation(UPDATE_DIRECTORY, {
    onCompleted(result) {
      toast(
        "success",
        `Successfully updated ${result.updateDirectory?.title}!`
      );
      handleClose();
      onDirectoryAdded();
    },
    onError(e) {
      toast("error", `Directory title already exists`);
      console.error(e);
    },
  });

  const handleFormData = () => {
    if (formType === "edit")
      setInitialValues({ ...initialValues, ...directory });
    else formikRef.current?.resetForm();
  };

  useEffect(() => {
    handleFormData();
  }, [directory]);

  // Handle image upload
  const handleImageUpload = async (files, setFieldValue) => {
    try {
      const response = await uploadImages(files);
      const imageUrl = response.images[0];
      setFieldValue("icon", imageUrl);
      toast("success", "Image uploaded successfully");
    } catch (error) {
      console.error("Error uploading image:", error);
      toast("error", "Failed to upload image");
    }
  };

  const handleSave = (values) => {
    const { title, icon } = values;
    if (!icon) {
      toast("error", "Please upload an icon.");
      return;
    }

    if (formType === "add") {
      addDirectory({
        variables: {
          directoryInput: {
            title,
            icon, // Ensure icon is included
            clickedFrom,
          },
        },
      });
    } else {
      updateDirectory({
        variables: {
          directoryInput: {
            _id: directory._id,
            title,
            icon, // Ensure icon is included
            clickedFrom,
          },
        },
      });
    }
  };

  // const handleSave = (values) => {
  //   const { title, icon } = values;

  //   if (formType === "add") {
  //     addDirectory({
  //       variables: {
  //         directoryInput: {
  //           title,
  //           icon,
  //         },
  //       },
  //     });
  //   } else {
  //     updateDirectory({
  //       variables: {
  //         directoryInput: {
  //           _id: directory._id,
  //           title,
  //           icon,
  //         },
  //       },
  //     });
  //   }
  // };

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      enableReinitialize={true}
      onSubmit={async (values, resetForm) => {
        handleSave(values, resetForm);
      }}
    >
      {({
        values,
        errors,
        isSubmitting,
        handleChange,
        handleBlur,
        resetForm,
        handleSubmit,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit}>
          <div className="mb-3 flex flex-col gap-6">
            <div className="w-full flex flex-col">
              <label>Icon</label>
              <label
                htmlFor="icon"
                className="w-full p-4 border-2 hover:cursor-pointer items-center rounded-xl h-[150px]"
              >
                {!values.icon && (
                  <div className="w-full flex flex-col justify-center h-full items-center">
                    <HiOutlineCloudUpload size={50} />
                    <p className=" text-[#667085] font-normal">
                      Click to upload or drag and drop
                    </p>
                  </div>
                )}
                <input
                  type="file"
                  name="icon"
                  id="icon"
                  onChange={(event) =>
                    handleImageUpload(event.currentTarget.files, setFieldValue)
                  }
                  className="mt-1 w-full hidden"
                />
                {errors.icon && (
                  <p className="text-sm text-red-600">{errors.icon}</p>
                )}
                {/* {values.icon && (
                  <img
                    src={values.icon}
                    alt="Uploaded Icon"
                    className="mt-2 h-24 w-24 object-cover"
                  />
                )} */}
                {values.icon && (
                  <div className="relative mt-2 h-24 w-24">
                    <img
                      src={values.icon}
                      alt="Uploaded Icon"
                      className="h-full w-full object-cover rounded"
                    />
                    <button
                      type="button"
                      className="absolute top-0 right-0 p-1 bg-gray-600 rounded-full text-white hover:bg-gray-800"
                      onClick={(e) => {
                        e.stopPropagation();
                        setFieldValue("icon", "");
                      }} // Clears the icon value
                    >
                      &times;{" "}
                      {/* You can also use an icon component here, like from FontAwesome or Heroicons */}
                    </button>
                  </div>
                )}
              </label>
            </div>

            <div className="w-full">
              <Input
                label={"Title"}
                name="title"
                error={errors.title}
                value={values["title"]}
                onChange={handleChange}
                type="text"
                required={true}
                placeholder={"Title"}
              />
            </div>
          </div>

          <div className="mt-6 flex space-x-2">
            <button
              type="button"
              disabled={addLoading || editLoading}
              onClick={() => handleClose()}
              className="flex w-1/2 justify-center rounded bg-secondary p-3 font-medium text-white outline hover:opacity-90 disabled:bg-slate-400"
            >
              Close
            </button>
            <button
              type="submit"
              disabled={isSubmitting || addLoading || editLoading}
              className="flex w-1/2 justify-center rounded bg-primary p-3 font-medium text-white hover:opacity-90 disabled:bg-slate-400"
            >
              {addLoading || editLoading ? (
                <div className="animate-spin">
                  <AiOutlineLoading3Quarters size={22} />
                </div>
              ) : (
                "Save"
              )}
            </button>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default DirectoryForm;
